
<template>
  <div
    class="co-flex-col rounded grey lighten-4 pa-6"
  >
    <div class="co-flex-row co-items-center">
      <v-avatar
        size="40"
        rounded="1"
      >
        <v-img
          :src="item.avatar || defAvatar"
        />
      </v-avatar>
      <div class="co-flex-col ml-2">
        <span class="text-body-1">{{ item.username }}</span>
      </div>
    </div>
    <span
      class="text-body-1 my-2"
      style="cursor: pointer;"
      @click="onTitle"
    >{{ item.title }}</span>
    <v-img
      v-if="item.cover"
      class="rounded"
      :src="item.cover"
      :aspect-ratio="ratio"
      cover
      @click="toContent"
    />
    <div class="co-flex-row co-justify-between co-items-center px-2 mt-2">
      <span class="co-text-xs co-text-gray-500">{{ item.createTime }}</span>
      <div class="co-flex-row co-items-center">
        <land-icon-forward
          :name="flagForward.name"
          :value="flagForward.value"
          :icon="flagForward.icon"
          :color="flagForward.color"
          margin="ma-0"
          @action="onContentForward"
        />
        <land-icon-comment
          name="comment"
          enabled
          margin="ml-10"
          :value="item.commentNum"
          @action="toContentComment"
        />
        <!-- <land-icon-collect
          :name="flagCollect.name"
          :value="flagCollect.value"
          :icon="flagCollect.icon"
          :color="flagCollect.color"
          margin="ml-10"
          @action="onContentCollect"
        /> -->
        <land-icon-like
          :name="flagLike.name"
          :value="flagLike.value"
          :icon="flagLike.icon"
          :color="flagLike.color"
          margin="ml-10"
          @action="onContentLike"
        />
      </div>
    </div>
    <dialog-alert ref="dlgAlertSub" />
  </div>
</template>

<script>
  import api from '@/api/co.api'
  import app from '@/api/co.app'
  import user from '@/api/co.user'
  import web from '@/api/web/co.web'

  export default {
    name: 'ContentSubcard',
    components: {
      DialogAlert: () => import('@/pages/sections/comm/DialogAlert.vue')
    },
    props: {
      item: {
        type: Object,
        default: () => {
          return {}
        }
      },
      ratio: {
        type: Number,
        default: 1.45
      }
    },
    data () {
      return {
        flagForward: {
          name: 'forward',
          value: '',
          icon: app.icon.forward,
          color: 'blue-grey',
          flag: web.comm.Flags.NO
        },
        flagCollect: {
          name: 'collect',
          value: '',
          icon: app.icon.collect,
          color: 'blue-grey',
          flag: web.comm.Flags.NO
        },
        flagLike: {
          name: 'like',
          value: '',
          icon: app.icon.like,
          color: 'blue-grey',
          flag: web.comm.Flags.NO
        },
        currUser: {},
        defAvatar: app.pic.avatar_user
      }
    },
    watch: {
      item: {
        handler (val) {
          this.flagForward.value = val.forwardedNum
          this.flagCollect.value = val.collectNum
          this.flagLike.value = val.likesNum

          this.getContentExtend({
            contentId: val.contentId
          })
        },
        immediate: true
      }
    },
    created () {
      this.currUser = user.info.get()
    },
    methods: {
      showAlert (type, message) {
        const dlg = this.$refs.dlgAlertSub
        if (dlg) {
          dlg.show({
            type,
            message
          })
        }
      },
      onTitle () {
        if (this.item.type === web.comm.ContentTypes.POUND) {
          if (this.extended) {
            const params = {
              title: this.item.title,
              type: this.item.type,
              talkTopic: this.item.talkTopic
            }
            api.page.navigate(this, 'pound', params)
          } else {
            const params = {}
            if (this.item.forwardedFlag === 'yes') {
              params.contentId = this.content.contentId
              params.title = this.content.title
              params.type = this.item.type
            } else {
              params.contentId = this.item.contentId
              params.title = this.item.title
              params.type = this.item.type
            }

            api.page.navigate(this, 'hotDetail', params)
          }
        } else {
          const params = {}
          if (this.item.forwardedFlag === 'yes') {
            params.contentId = this.content.contentId
            params.title = this.content.title
            params.type = this.item.type
          } else {
            params.contentId = this.item.contentId
            params.title = this.item.title
            params.type = this.item.type
          }

          api.page.navigate(this, 'hotDetail', params)
        }
      },
      toContent () {
        const params = {}
        if (this.item.forwardedFlag === 'yes') {
          params.contentId = this.content.contentId
          params.title = this.content.title
          params.type = this.item.type
        } else {
          params.contentId = this.item.contentId
          params.title = this.item.title
          params.type = this.item.type
        }

        api.page.navigate(this, 'hotDetail', params)
      },
      onContentForward (ev) {
        const me = this
        const text = api.comm.trim(ev.content)
        if (text) {
          const contentId = this.item.contentId
          const executed = function (res) {
            // console.log({ res })
            if (res.status) {
              let value = parseInt(me.flagForward.value, 10) || 0
              value += 1
              me.setForwardFlag({
                forwardedFlag: web.comm.Flags.YES,
                forwardedNum: value
              })
              me.$message({
                message: '内容转发成功！',
                type: 'success'
              })
            } else {
              me.$message({
                message: '内容转发失败！',
                type: 'error'
              })
            }
          }

          web.behaviour.forward({
            contentId,
            forwardedText: text,
            executed
          })
        } else {
          this.$alert('填好转发后显示标题才能转发！', '提示', {
            confirmButtonText: '好的',
            type: 'warning'
          })
        }
      },
      toContentComment (ev) {
        const me = this
        const commentContent = api.comm.trim(ev.content)
        if (commentContent) {
          const data = {
            commentContent,
            contentId: this.item.contentId,
            username: this.currUser.username,
            avatar: this.currUser.avatar
          }

          const executing = function () {}
          const executed = function (res) {
            if (res.status) {
              let value = parseInt(me.item.commentNum, 10) || 0
              value += 1
              me.$set(me.item, 'commentNum', value)
              me.showAlert('success', '评论成功')
            } else {
              const err = res.data || { code: 9000, desc: '未知错误' }
              me.showAlert('error', `${err.code}-${err.desc}`)
            }
          }
          web.comment.create({
            data,
            executing,
            executed
          })
        } else {
          this.$alert('填好评论内容后才能发布！', '提示', {
            confirmButtonText: '好的',
            type: 'warning'
          })
        }
      },
      onContentLike (ev) {
        const me = this
        let prefix = ''
        let expect = ''
        const actual = this.flagLike.flag
        let value = parseInt(me.flagLike.value, 10) || 0
        if (actual === web.comm.Flags.NO) {
          value += 1
          prefix = '点赞'
          expect = web.comm.Flags.YES
        } else {
          value -= 1
          prefix = '取消点赞'
          expect = web.comm.Flags.NO
        }

        const executed = function (res) {
          if (res.status) {
            me.setLikeFlag({
              likesFlag: expect,
              likesNum: value
            })
            me.$message({
              type: 'success',
              message: `${prefix}成功！`
            })
          } else {
            me.$message({
              type: 'error',
              message: `${prefix}失败！`
            })
          }
        }

        if (actual === web.comm.Flags.NO) {
          web.behaviour.like({
            contentId: this.item.contentId,
            executed
          })
        } else {
          web.behaviour.cancel({
            id: this.item.contentId,
            type: this.flagLike.name,
            executed
          })
        }
      },
      onContentCollect (ev) {
        const me = this
        let prefix = ''
        let expect = ''
        const actual = this.flagCollect.flag
        let value = parseInt(me.flagCollect.value, 10) || 0
        if (actual === web.comm.Flags.NO) {
          value += 1
          prefix = '收藏'
          expect = web.comm.Flags.YES
        } else {
          value -= 1
          prefix = '取消收藏'
          expect = web.comm.Flags.NO
        }

        const executed = function (res) {
          // console.log({ res })
          if (res.status) {
            me.setCollectFlag({
              collectionFlag: expect,
              collectNum: value
            })
            me.$message({
              type: 'success',
              message: `${prefix}成功！`
            })
          } else {
            me.$message({
              type: 'error',
              message: `${prefix}失败！`
            })
          }
        }

        if (actual === web.comm.Flags.NO) {
          web.behaviour.collect({
            contentId: this.item.contentId,
            executed
          })
        } else {
          web.behaviour.cancel({
            id: this.item.contentId,
            type: this.flagCollect.name,
            executed
          })
        }
      },
      setCollectFlag (data) {
        const collectFlag = data.collectionFlag || web.comm.Flags.NO

        let flag = null
        if (collectFlag === web.comm.Flags.NO) {
          flag = web.flags.no['collect']
        } else {
          flag = web.flags.yes['collect']
        }
        this.flagCollect = Object.assign({}, this.flagCollect, flag)
        this.flagCollect.flag = collectFlag
        this.flagCollect.value = data.collectNum || 0
      },
      setForwardFlag (data) {
        const forwardFlag = data.forwardedFlag || web.comm.Flags.NO

        let flag = null
        if (forwardFlag === web.comm.Flags.NO) {
          flag = web.flags.no['forward']
        } else {
          flag = web.flags.yes['forward']
        }
        this.flagForward = Object.assign({}, this.flagForward, flag)
        this.flagForward.flag = forwardFlag
        this.flagForward.value = data.forwardedNum || 0
      },
      setLikeFlag (data) {
        const likeFlag = data.likesFlag || web.comm.Flags.NO

        let flag = null
        if (likeFlag === web.comm.Flags.NO) {
          flag = web.flags.no['like']
        } else {
          flag = web.flags.yes['like']
        }
        this.flagLike = Object.assign({}, this.flagLike, flag)
        this.flagLike.flag = likeFlag
        this.flagLike.value = data.likesNum || 0
      },
      setFlags (data) {
        this.setForwardFlag(data)
        this.setCollectFlag(data)
        this.setLikeFlag(data)
      },
      getContentExtend ({
        contentId
      }) {
        const me = this
        const executed = function (res) {
          const data = Object.assign({}, res.data)
          data.collectNum = me.item.collectNum
          data.forwardedNum = me.item.forwardedNum
          data.likesNum = me.item.likesNum
          me.setFlags(data)
        }

        user.info.extendContent({
          contentId,
          executed
        })
      },
    }
  }
</script>
